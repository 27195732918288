<template>
  <CBox>
    <template v-if="$store.state.auth.isAuthenticated">
      <HeaderCompetition />
    </template>
    <CBox
      width="100%"
      height="100%"
      pt="200"
      verticalAlign="middle"
      textAlign="center"
    >
      <CHeading fontFamily="Montserrat" color="fontMain" mb="4" size="2xl">
        404
      </CHeading>
      <CHeading fontFamily="Montserrat" color="fontMain" size="1xl">
        Ups... Halaman tidak ditemukan ;(
      </CHeading>
    </CBox>
  </CBox>
</template>
<script>
import { CBox, CHeading } from "@chakra-ui/vue";
import HeaderCompetition from "@/components/header/HeaderCompetition.vue";

export default {
  metaInfo: {
    title: "Page Not Found",
    titleTemplate: "Devcode - %s",
  },
  name: "Page Not Found",
  components: {
    HeaderCompetition,
    CBox,
    CHeading,
  },
};
</script>
